<template>
<div class="animated fadeIn fit">
    <div v-if="$q.screen.lt.sm" class="text-right">
        <q-btn :icon="show_tabs ? 'close' : 'menu'" @click.prevent="show_tabs=!show_tabs" flat :color="show_tabs ? 'dark' : 'primary'" />
    </div>
    <q-tabs :class="mobile_tabs" clickable hoverable no-caps v-model="tab" class="mb-1 personal-file-tabs" align="left">
        <q-tab class="custom-tab text-positive" name="muscles">
            <div>
                <div class="tab-text">
                    <i class="fas fa-bone text-positive mr-2 fa-lg" :class="is_muscles ? 'fa-blink' : ''"></i> <strong class="text-dark">{{ translate('muscles') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab class="custom-tab text-negative" name="medical_problems">
            <div>
                <div class="tab-text">
                    <i class="fas fa-heart-broken text-negative mr-2 fa-lg" :class="is_medical_problems ? 'fa-blink' : ''"></i> <strong class="text-dark">{{ translate('medical_problems') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab class="custom-tab text-pink" name="units">
            <div>
                <div class="tab-text">
                    <i class="fa fa-balance-scale text-pink mr-2 fa-1x" :class="is_units ? 'fa-blink' : ''"></i> <strong class="text-dark">{{ translate('units') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab class="custom-tab text-orange" name="measurements">
            <div>
                <div class="tab-text">
                    <i class="fas fa-ruler text-orange mr-2 fa-1x" :class="is_measurements ? 'fa-blink' : ''"></i> <strong class="text-dark">{{ translate('measurements') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab @click.stop="$router.push({name: 'GeneralData', params: { tab: 'equipment' }})" class="custom-tab text-accent" name="equipment">
            <div>
                <div class="tab-text">
                    <i class="fas fa-chair text-accent mr-2 fa-lg" :class="is_equipments ? 'fa-blink' : ''"></i> <strong class="text-dark">{{ translate('equipment') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab @click.stop="$router.push({name: 'GeneralData', params: { tab: 'objectives' }})" class="custom-tab text-blue" name="objectives">
            <div>
                <div class="tab-text">
                    <i class="fas fa-trophy text-blue mr-2 fa-lg" :class="is_objectives ? 'fa-blink' : ''"></i> <strong class="text-dark">{{ translate('objectives') }}</strong>
                </div>
            </div>
        </q-tab>
    </q-tabs>
    <div class="mb-4">
        <template v-if="is_muscles">
            <muscles />
        </template>
        <template v-if="is_medical_problems">
            <medical-problems />
        </template>
        <template v-if="is_units">
            <units />
        </template>
        <template v-if="is_measurements">
            <measurements />
        </template>
        <template v-if="is_equipments">
            <equipments />
        </template>
        <template v-if="is_objectives">
            <objectives />
        </template>
    </div>
</div>
</template>

<script>
import _ from 'lodash'
import * as jwt_decode from 'jwt-decode'
import { eventBus } from '../../../main'
import Muscles from './tab-panels/Muscles'
import MedicalProblems from './tab-panels/MedicalProblems'
import Equipments from './tab-panels/Equipments'
import Units from './tab-panels/Units'
import Measurements from './tab-panels/Measurements'
import Objectives from './tab-panels/Objectives'

export default {
    name: 'GeneralData',
    components: {
        'muscles': Muscles,
        'medical-problems': MedicalProblems,
        'equipments': Equipments,
        'measurements': Measurements,
        'units': Units,
        'objectives': Objectives,
    },
    computed: {
        is_muscles: function () {
            return this.tab === 'muscles'
        },
        is_medical_problems: function () {
            return this.tab === 'medical_problems'
        },
        is_units: function () {
            return this.tab === 'units'
        },
        is_measurements: function () {
            return this.tab === 'measurements'
        },
        is_equipments: function () {
            return this.tab === 'equipment'
        },
        is_objectives: function () {
            return this.tab === 'objectives'
        },
        mobile_tabs: function () {
            if (this.$q.screen.lt.sm) {
                return !this.show_tabs ? 'hidden' : 'mobile-tabs bg-white'
            }
            return ''
        },
    },
    data: function () {
        return {
            show_tabs: this.$q.screen.lt.sm ? false : true,
            tab: 'muscles',
        }
    },
    created: function () {
        eventBus.$on('hideTabs', () => {
            this.hideTabs()
        })
    },
    methods: {
        hideTabs: function () {
            this.show_tabs = false
        },
    },
}
</script>

<style lang="scss" scoped>
body .custom-tab {
    background-clip: border-box;
    border: 1px solid #c8ced3;
    border-radius: 0.25rem;
    margin: 0 .25rem 0 0;
    padding: 0;
    background: #fff;

    &:hover {
        background: inherit;
    }
    div {
        display: block;
    }
    .image-holder {
        // width: 300px;
        width: 215px;
        display: block;
        height: 90px;
        margin: -4px 0 0.25rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .tab-text {
        // max-width: 185px;
        max-width: 100%;
        padding: 2px 15px 4px;
    }
}

@media screen and (max-width: 320px) {
    body .custom-tab {
        .image-holder {
            width: 200px;
        }
        .tab-text {
            max-width: 170px;
        }
    }
}
.align-self-start {
    display: inline!important;
}
.bg-transparent {
    background-color: transparent;
}
</style>
